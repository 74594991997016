import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $("select:not(.no-select2)").select2();
    this.toggleEnded($("#end_type_select").val())
  }

  toggle(e) {
    this.toggleEnded(e.target.value)
  }

  toggleEnded(target_to) {
    let end_types_array, hidden_end_types_array;
    end_types_array = ['active', 'ended'];

    $(`#${target_to}`).show();

    hidden_end_types_array = end_types_array.filter(function(value, index, arr) {
      return value !== target_to;
    });

    hidden_end_types_array.map(function(x) {
      return $(`#${x} :input`).val(null);
    });

    $((hidden_end_types_array.map(function(x) {
      return "#" + x;
    })).toString()).hide();
  }
}
