import { Controller } from "stimulus";
import flatpickr from "flatpickr";

function updateTurboFrame(turboFrame, params) {
  const src = new URL(turboFrame.getAttribute("src"));

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      src.searchParams.delete(key);
      value.forEach((v) => src.searchParams.append(key, v));
    } else {
      src.searchParams.set(key, value);
    }
  });

  turboFrame.innerHTML = Shared.get_loader();
  turboFrame.setAttribute("src", src.toString());
}

function findTurboFrame(element) {
  return $(element).parents('turbo-frame[loading="lazy"]')[0];
}

export default class extends Controller {
  static targets = ["datePicker", "orderByDropdown", "selectedHeadersButton"];

  connect() {
    const value = this.element.dataset.analysisFormValue;
    const setupMethods = {
      "_picker": this.setupDatePicker,
      "_order_by": this.setupOrderByDropdown,
      "_selected_headers": this.setupSelectedHeadersCheckbox,
    };

    Object.keys(setupMethods).forEach((suffix) => {
      if (value.endsWith(suffix)) setupMethods[suffix].call(this, value);
    });
  }

  setupDatePicker(id) {
    const input = this.element.querySelector(`#${id}`);
    flatpickr(input, {
      mode: "range",
      onClose: () => {
        const turboFrame = findTurboFrame(this.element);
        if (!turboFrame) return;

        const [start_date, end_date = start_date] = input.value.split(" to ");
        updateTurboFrame(turboFrame, { start_date, end_date });
      },
    });
  }

  setupOrderByDropdown(id) {
    const select = this.element.querySelector(`#${id}`);
    select.addEventListener("change", (e) => {
      const turboFrame = findTurboFrame(this.element);
      if (!turboFrame) return;

      updateTurboFrame(turboFrame, { order_by: e.currentTarget.value });
    });
  }

  setupSelectedHeadersCheckbox(id) {
    const button = this.element.querySelector(`#${id}`);
    if (!button) {
      console.error(`Button with ID "${id}" not found.`);
      return;
    }

    button.addEventListener("click", (e) => {
      e.preventDefault();

      const modal = button.closest(".modal");
      const checkboxes = modal.querySelectorAll('input[name="selected_headers[]"]');

      let selectedHeaders = Array.from(checkboxes)
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value);

      const turboFrame = findTurboFrame(this.element);
      if (!turboFrame) return;

      updateTurboFrame(turboFrame, { "selected_headers[]": selectedHeaders });
    });
  }
}
