import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["messages", "input"];

  submit(event) {
    const messageContent = this.inputTarget.value.trim();
    if (!messageContent) return;

    const userMessage = `
      <li class="conversation-outbound float-right w-100 pt-4">
        <div>
          <div class="conversation-message-text">${messageContent}</div>
        </div>
      </li>
    `;
    this.messagesTarget.insertAdjacentHTML("beforeend", userMessage);
    const typingIndicator = `
      <li class="conversation-inbound float-left" id="typing-indicator">
        <div>
          <div class="conversation-message-text">Typing...</div>
        </div>
      </li>
    `;
    this.messagesTarget.insertAdjacentHTML("beforeend", typingIndicator);
    this.inputTarget.value = "";
    this.scrollToBottom();
  }
  scrollToBottom() {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;
  }
}
